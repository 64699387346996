<template>
  <en-drawer title="单据历史" :model-value="modelValue" @close="$emit('update:model-value', false)" :size="1200">
    <en-table :data="data?.logs">
      <en-table-column label="操作人" prop="operator.name"></en-table-column>
      <en-table-column label="操作" prop="message"></en-table-column>
      <en-table-column label="时间">
        <template #default="{ row }: { row: EnocloudServiceDefinitions['DocumentLogDto'] }">{{ formatDate(row.datetime, true) }} </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="$emit('update:model-value', false)">取消</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceQuotationDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  }
})
</script>
